








import { Component } from 'vue-property-decorator';
import BaseToolbar from './BaseToolbar.vue';

@Component
export default class CommonBottomToolbar extends BaseToolbar {
}
