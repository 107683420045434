var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.isEmbedded &&
    _vm.$currentUser.isDualOrAllowViewOnly &&
    _vm.canSwitchModes
    ? _c(
        "v-toolbar",
        [
          _c(
            "v-row",
            {
              staticClass: "text-center justify-space-between",
              attrs: { elevation: "1", outlined: "" },
            },
            [_c("pb-user-mode-selector")],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }